@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@400;700&family=Raleway:wght@600&display=swap');
@import "../components/faq/faq.scss";


//$headings-font-family: 'Raleway', sans-serif;
$headings-font-family: shadows-into-light, sans-serif;
$headings-font-weight: 600;


$font-size-base: 1.15rem;

$font-family-base: 'Fuzzy Bubbles', cursive;
$font-weight-base: 400;

p {
  letter-spacing: 0.9px;
  line-height: 1.8em;
}

$font-family-sans-serif: shadows-into-light, sans-serif;

$line-height-base: 1.6;

$darkLiver: rgba(0, 0, 00, 0);
$isabelline: rgba(241, 247, 237, 1);
$etonBlue: #843ca5;
$dingyDungeon: rgba(179, 57, 81, 1);
$flax: #67c9b8;

$primary: #83EC94FF;
$secondary: #456ccc;
//$success: ;
//$info: ;
//$warning: ;
//$danger: ;
$light: #8c8c8c;
$dark: #171515;

$primary-color: #68da7b;
$secondary-color: #e86c6c;
$body-color: #fafafa;

$white: #a3e1ad;



//$grid-breakpoints: (
//        xs: 0,
//        sm: 700px,
//        md: 900px,
//        lg: 1200px,
//        xl: 1500px,
//        xxl: 2300px
//);
//$container-max-widths: (
//        sm: 600px,
//        md: 800px,
//        lg: 1100px,
//        xl: 1300px,
//        xxl: 2000px
//);

// Import Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";