/**
Size of the animated elves
 */
/* xs */
img.elf-banner-layer {
  width: 100%;
  height: auto;
}

/* sm */
@media (min-width: 768px) {
  img.elf-banner-layer {
    max-width: 100%;
  }
}

/* md */
@media (min-width: 992px) {
  img.elf-banner-layer {
    max-width: 80%;
  }
}

/* lg */
@media (min-width: 1200px) {
  img.elf-banner-layer {
    max-width: 75%;
  }
}


/**
Sections and reusable classes
 */
.raining.raining-top {
  mask-image: none !important;
  min-height: 600px;
  display: block;
  position: fixed;
  z-index: -1;
  background-image: none !important;
}

.raining {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  //background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/221808/sky.jpg");
  //background: rgb(255,29,37);
  //background: radial-gradient(circle, rgba(255,29,37,1) 0%, rgba(107,23,29,0.89) 95%, rgba(107,23,29,1) 100%);
  background-size: 100%;
  background-position: -10% 0%;
  padding: 80px 0 120px;
  mask-image: linear-gradient(
                  to bottom,
                  #e15e5e 5%,
                  #7e2d2d 90%,
                  transparent 98%,
                  transparent 100%,
                  #7e2d2d 90%
  );
  background-image: linear-gradient(
                  to bottom,
                  rgba(240, 88, 87, 0),
                  rgba(242, 108, 107, 1)
  );

  .elf-banner-top {
    mask-image: none;
  }

  .elf-banner-title {
    //height: 300px;

  }

  .elf-banner-content {
    //background-image: radial-gradient(
    //                circle,
    //                rgba(232, 176, 176, 0.5),
    //                rgba(192, 142, 142, 0.5),
    //                rgba(199, 161, 161, 0.5),
    //                rgba(227, 169, 169, 0.5),
    //                rgba(232, 156, 155, 0.5),
    //                rgba(225, 144, 144, 0.5),
    //                rgba(211, 136, 137, 0.5),
    //                rgba(206, 139, 141, 0.5),
    //                rgba(161, 92, 95, 0.5),
    //                rgba(189, 119, 124, 0.5),
    //                rgba(171, 91, 98, 0.5),
    //                rgba(148, 68, 76, 0.5)
    //);
    padding-top: 300px;
    //background-image: linear-gradient(
    //                to bottom,
    //                rgba(240, 88, 87, 0),
    //                rgba(242, 108, 107, 1)
    //);
    //background-color: rgba(255,255,255,0.6);

  }

  .elf-banner-layer {
    position: absolute;
    display: flex;
    z-index: -300;
    opacity: 0.9;
  }

  .animate-01 {
    animation: float 5.8s ease-in-out infinite;
  }

  .animate-02 {
    animation: float 4.8s ease-in-out infinite;
  }

  .animate-03 {
    animation: float 6.1s ease-in-out infinite;
  }

  .animate-04 {
    animation: float 5.2s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
      transform: translatey(0px);
    }
    50% {
      //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
      transform: translatey(-20px);
    }
    100% {
      //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
      transform: translatey(0px);
    }
  }
}

.raining-background {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 500px;


}

.circle-container {
  $particleNum: 200;
  $particleColor: hsl(180, 100%, 80%);

  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(
                    hsl(180, 100%, 80%),
                    hsl(180, 100%, 80%) 10%,
                    hsla(180, 100%, 80%, 0) 56%
    );

    animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

    @keyframes fade-frames {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scale-frames {
      0% {
        transform: scale3d(0.4, 0.4, 1);
      }

      50% {
        transform: scale3d(2.2, 2.2, 1);
      }

      100% {
        transform: scale3d(0.4, 0.4, 1);
      }
    }
  }

  $particleBaseSize: 12;


  @for $i from 1 through $particleNum {
    &:nth-child(#{$i}) {
      $circleSize: random($particleBaseSize);
      width: $circleSize + px;
      height: $circleSize + px;

      $startPositionY: random(10) + 100;
      $framesName: "move-frames-" + $i;
      $moveDuration: 28000 + random(9000) + ms;

      animation-name: #{$framesName};
      animation-duration: $moveDuration;
      animation-delay: random(37000) + ms;

      @keyframes #{$framesName} {
        from {
          transform: translate3d(
                          #{random(100) + vw},
                          #{- $startPositionY - random(30) + vh},
                          0
          );
        }

        to {
          transform: translate3d(
                          #{random(100) + vw},
                          #{$startPositionY + vh},
                          0
          );
        }
      }

      .circle {
        animation-delay: random(4000) + ms;
      }
    }
  }
}
