.RoadmapSlickSlider {
  //min-height: 600px;
  //position: absolute;
}
.RoadmapSlickStation {
  padding: 3em
}
.rm-container {
  padding-right: 0;
  padding-left: 0;
}

.rm-parallax, .rm-container {
  background-image: url("../../assets/elf/dirt-road-section.png");
  background-color: rgba(35, 24, 56, 0.92);
  background-size: 250px;
  background-position-x: 98%;
  background-attachment: fixed, fixed;
  background-repeat: repeat-y;
  background-position-y: 20%;
  height: 100%!important;
  position: relative;
  padding: 2em 0em
}
.rm-parallax-layer {
  background-color: rgba(189, 82, 82, 0.82);
  padding: 2em 1em;
}

$options: (
        1: rgba(226, 82, 82, 0.6),
        2: rgba(201, 93, 178, 0.63),
        3: rgba(103, 78, 173, 0.81),
        4: rgba(83, 167, 224, 0.6),
        5: rgba(86, 183, 132, 0.7),
        6: rgba(175, 175, 67, 0.66),
        7: rgba(239, 97, 17, 0.65)
);

$elements: 9;

@function roadmap-color-index($index) {
  $color: map-get($options, $index);
  @return $color;
}

@for $i from 1 to $elements {
  .rm-parallax-layer:nth-child(#{$i}) {
    background-image: linear-gradient(to right, rgba(30, 75, 115, 0), rgba(30, 75, 115, 0) 30%, rgba(255, 255, 255, 0.1) 70%, roadmap-color-index($i));
    //background-color: roadmap-color-index($i);
    position: relative;
  }
}


