@import "./bootstrap";

.navbar-dark .navbar-nav .nav-link {
  font-size: 1.4em;
}


h1, h2, h3, h4 {
  text-shadow: 2px 2px #20385e;
  letter-spacing: 4.5px;
}

body {
  background: rgb(0, 0, 0);
  //background: linear-gradient(150deg, rgb(35, 35, 35) 0%, #20385e 30%, #1f4f93 100%); // Bobby's bg
  background-image: radial-gradient(circle, #f27575, #f26c6b, #f16261, #f05857, #ef4d4c, #eb4848, #e84245, #e43c41, #dd3b43, #d53a45, #ce3946, #c63847);
}

.new-font {
  font-family: shadows-into-light, sans-serif;
  font-weight: 400;
  font-style: normal;
}

//.xxx {
//  display: none;
//}
//@include media-breakpoint-up(sm) {
//  .xxx {
//    display: block;
//  }
//}


.header-logo {
  width: 6em;

  img {
    max-width: 6em;
  }
}

.header-social-links {
  margin-left: 2em;
}

.header-social-icon {
  border: 1px $primary-color solid;
  //width: 60px;
  //height: 60px;
  padding: 1em 0.3em;
  margin-left: 1em;

  img {
    width: 40px;
    margin: 1em;
  }
}

.header-menu {
  a.nav-link {
    color: $body-color !important;
  }
}

footer {
  color: $body-color;

  a {
    color: $body-color;
  }
}

.hero {
  img {
    margin: -120px auto;
  }
}

.project-card {
  background: rgb(103, 201, 184);
  background: radial-gradient(circle, $light 0%, $secondary 90%, $primary 100%);

  .project-card-drop {
    color: #fafafc;
  }
;
}


.message {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: white;
  font-family: "Josefin Slab", serif;
  line-height: 27px;
  font-size: 18px;
  text-align: right;
  pointer-events: none;
  animation: message-frames 1.5s ease 5s forwards;
  opacity: 0;

  @keyframes message-frames {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.footer {
  //background-image: linear-gradient(to bottom, #d03945, #a02a36, #731d27, #481118, #240000);
  background-image: linear-gradient(
                  to bottom,
                  rgba(208, 57, 69, 0.2),
                  rgba(199, 52, 65, 0.25),
                  rgba(189, 48, 60, 0.7),
                  rgba(180, 43, 56, 0.7),
                  rgba(171, 38, 52, 0.7), rgba(155, 33, 47, 0.7),
                  rgba(140, 29, 42, 0.7), rgba(125, 24, 37, 0.7),
                  rgba(100, 19, 30, 0.7), rgba(77, 15, 23, 0.7),
                  rgba(54, 10, 14, 0.7), rgba(36, 0, 0, 0.7));
}